import React, { useState } from 'react'
import { useCourseSwrData } from '../../../swr/swrWrapper'
import CourseList from '../components/CourseList'
import { useAtom } from 'jotai'
import { addCourseAtom, referralState, couponState } from '../data/store'

function Course() {
  const { data, error } = useCourseSwrData('/private/courses?courseName=&duration=')
  const [addCourse, setAddCourse] = useAtom(addCourseAtom)
  const [openRefrral, setOpen] = useAtom(referralState)
  const [openCoupon, setOpenCoupon] = useAtom(couponState)

  return (
    <>
      <div className='flex-row-start'>
        <h2 className='heading'>Course</h2>
        <div className='flex fle-row gap-2'>
          <button onClick={() => { setOpenCoupon(true);}} className='btn-secondary outline outline-2 outline-primary'>Create Coupon</button>
          <button onClick={() => { setOpen(true) }} className='btn-secondary outline outline-2 outline-primary'>Edit Referrals</button>
          <button onClick={() => setAddCourse(true)} className='btn-secondary outline outline-2 outline-primary'>Add Course</button>
        </div>
      </div>
      <CourseList data={data} />
    </>
  )
}

export default Course