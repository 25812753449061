export const getYtEmbedLink = (link) => {
    
    const pattern = new RegExp('^(https?:\\/\\/)?'+ 
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
    '(\\#[-a-z\\d_]*)?$','i');

    if(pattern.test(link)){
        if(link.includes('https://www.youtube.com/embed/'))
            return link
        const URL = link.split("v=")[1].substring(0, 11);
        const VIDEOURL = `https://www.youtube.com/embed/${URL}`
        return VIDEOURL;
    }
    else{
        throw new Error("This is not the correct link")
    }
}