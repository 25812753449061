import { courseAxiosInstance } from '../../../../axios/axiosInstance'


export const editCoupon = async (data) => {
    const res = await courseAxiosInstance.post("/private/courses/courses/coupon/edit", {
        code: data.coupon,
        discount: data.discountoncoupon,
        couponid: "642276e5ed4c8f355f32d080"
    });
}

