import { useAtom } from 'jotai'
import { userAtom } from '../../data/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

function ProtectedRoute({children}) {
  const [user, setUser] = useAtom(userAtom)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (!user.user || user.user === null || user.user === undefined|| user.token === null || user.token === undefined) {
      navigate('/auth/login')
    }
  }, [user, navigate])



  // if(pathname.includes('admins') && user?.user?.permissions[0].superAdmin === false) {
  //   navigate('/')
  // }

  // if(pathname.includes('courses') && user?.user?.permissions[0].courses === false) {
  //   navigate('/')
  // }

  // if(pathname.includes('mantra') && user?.user?.permissions[0].mantra === false) {
  //   navigate('/')
  // }

  // if(pathname.includes('matricula') && user?.user?.permissions[0].matricula === false) {
  //   navigate('/')
  // }

  return (
    children
  )
}

export default ProtectedRoute