import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { uploadImageToCloud } from '../../../api/imageUpload';
import Input from '../../../components/Input/Input'

import { useAtom } from 'jotai';
import { newCourseAtom } from '../data/store';

function File({inputField}) {
    const [newCourse, setNewCourse] = useAtom(newCourseAtom)

    const [uploading, setUploading] = useState(false)
    const [uploaded,setUploaded] = useState(false)
        //IMAGE UPLOAD TO DO 

        const addFileToCloud = async (e) => {
            e.preventDefault();
            setUploading(true)
            if (newCourse.file) {
                // Update UI to show file is uploading
                const file = newCourse.featureImage;
                //setting filename
    
                // Create FormData and pass picked file with other necessary details
                const formData = new FormData();
                formData.append("file", file);
                formData.append("id", "matricula-courses");
    
    
    
                var allowedExtensions =
                    /(\.jpg|\.JPG|\.jpeg|\.JPEG|\.png|\.PNG)$/i;
    
                if (!allowedExtensions.exec(file.name)) {
                    toast.error('Only .jpg, .jpeg, .png files are allowed');
                    return false;
                }
                else {
                    try{
                        const res = await uploadImageToCloud(formData)
                        toast.success('File Uploaded Successfully');
                        setNewCourse({...newCourse,featureImage:res.data.Location})
                        setUploaded(true)
                    }
                    catch(err){
                        toast.error('File Upload Failed');
                        console.log(err)
                    }
                }
            }
            else {
                toast.error('Please select a file first');
            }
            setUploading(false)
        }
    
  return (
    <div className='flex-col-4'>
        <Input {...inputField}/>
        <button disabled={uploading} onClick={addFileToCloud} className={`btn-primary ${uploading && 'bg-purple-700'} ${uploaded && 'bg-green-600'} transition-all`}>Upload{uploading && 'ing...'}{uploaded && 'ed'} Image</button>
    </div>
  )
}

export default File