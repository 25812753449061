import { courseAxiosInstance as axios } from "../axios/axiosInstance";

export const addCourse = async (data) => {
    const res = await axios.post("/private/courses/new-course", data);
    return res.data;
}

export const updateCourse = async (data) => {
    if(data.price === 'paid')
        data.price = data.priceValue;
    await delete data.priceValue;
    const res = await axios.put(`/private/courses/${data.courseSlug}`, data);
    return res.data;
}

export const toggleCourseStatus = async ({courseSlug, isActive, courseName}) => {
    const res = await axios.put(`/private/courses/${courseSlug}`, { isActive, courseName });
    return res.data;
}

export const deleteCourse = async (slug) => {
    const res = await axios.delete(`/private/courses/${slug}`);
    return res.data;
}

export const addModuleToDB = async (moduleTitle,courseId, courseSlug) => {
    const res = await axios.post(`/private/courses/${courseSlug}/module/new`, { title: moduleTitle, course: courseId });
    return res.data;
}

export const editModuleInDB = async ({moduleSlug,moduleTitle, courseSlug}) => {
    const res = await axios.put(`/private/courses/modules/${moduleSlug}`, { title: moduleTitle, courseSlug });
    return res.data;
}

export const deleteModuleFromDB = async (moduleSlug) => {
    const res = await axios.delete(`/private/courses/modules/${moduleSlug}`);
    return res.data;
}

export const addChapterToDB = async ({chapterName,chapterVideoLink,moduleSlug, courseSlug, module}) => {
    const res = await axios.post(`/private/courses/${courseSlug}/module/${moduleSlug}/chapter/new`, { title: chapterName,  module ,video_link: chapterVideoLink });
    return res.data;
}

export const editChapterInDB = async ({chapterName,chapterVideoLink,moduleSlug, chapterSlug, preview, courseSlug}) => {
    const res = await axios.put(`/private/courses/module/${moduleSlug}/chapter/${chapterSlug}`, { title: chapterName,  video_link: chapterVideoLink, chapterSlug, moduleSlug, courseSlug, preview });
    return res.data;
}

export const deleteChapterFromDB = async (moduleSlug,chapterSlug) => {
    const res = await axios.delete(`/private/courses/modules/${moduleSlug}/chapters/${chapterSlug}`);
    return res.data;
}