import { atom } from "jotai";

const adminData = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    permissions: [
        {
            superAdmin: false,
            mantra: false,
            matricula: false,
            courses: false
        }
    ]
}


export const newAdminAtom = atom(adminData)
export const editAdminAtom = atom(adminData)

export const showPasswordsAtom = atom(false)
export const errorAtom = atom(null)
export const apiRequestAtom = atom({
    loading: false,
    success: null
})