import {useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import { createAdmin, editAdmin } from '../../../api/admin'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { newAdminAtom, showPasswordsAtom, errorAtom, apiRequestAtom } from '../logic'
import { adminAtom, userAtom } from '../../../data/store'
import { useSearchParams } from 'react-router-dom'

export const useAdminLogic = () => {

    const navigate = useNavigate()

    const [allAdmins,setAllAdmins] = useAtom(adminAtom)
    const [newAdmin,setNewAdmin] = useAtom(newAdminAtom)
    const [user,setUser] = useAtom(userAtom)

    const [showPasswords,setShowPasswords] = useAtom(showPasswordsAtom)
    const [error, setError] = useAtom(errorAtom)

    const [searchParams] = useSearchParams()
    
    const editId = searchParams.get('id');

    useEffect(() => {
        if(editId !== null && inputFields[0].value === ''){
            navigate('/admins')
        }
        setError(null)
    }, [editId])
    
    useEffect(() => {
        if(editId !== null){
            setNewAdmin({...allAdmins.find(admin => admin._id === editId),password: '',confirmPassword: ''})
        }
        else {
            setNewAdmin({
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
                permissions: [
                    {
                        superAdmin: false,
                        mantra: false,
                        matricula: false,
                        courses: false
                    }
                ]
            })
        }
    },[editId,allAdmins])
    
    
    const validatePassword = () => {
        if((newAdmin?.confirmPassword !== null && newAdmin?.confirmPassword !== '') && newAdmin?.password !== newAdmin?.confirmPassword){
            return false
        }
        return true
    }


    useEffect(() => {
        if(editId === null){
            if(!validatePassword()){
                setError('Passwords do not match')
            }
        }else{
            setError(null)
        }
    },[newAdmin?.password,newAdmin?.confirmPassword, editId])

    const [requestStatus,setRequestStatus] = useAtom(apiRequestAtom)

    const submitNewAdminForm = (e) => {
        e.preventDefault()
        setRequestStatus({...requestStatus,loading: true})
        if(!validatePassword()) {
            setError('Passwords do not match')
            setRequestStatus({...requestStatus,loading: false})
            return
        }
        if(Object.keys(newAdmin?.permissions[0]).reduce((acc,curr) => acc || newAdmin?.permissions[0][curr],false) === false) {
            setError('Please select at least one permission')
            setRequestStatus({...requestStatus,loading: false})
            return
        }
        delete newAdmin?.confirmPassword
        try{
            const res = createAdmin(newAdmin)
            setRequestStatus({...requestStatus,loading: false,success: true})
            toast.success('New Admin Added Successfully')
            navigate('/admins')
            window.location.reload()
        }
        catch(err){
            setError(err)
            setRequestStatus({...requestStatus,loading: false})
            toast.error('Something went wrong')
        }
    }

    const submitEditAdminForm = (e) => {
        e.preventDefault()
        setRequestStatus({...requestStatus,loading: true})
        if(newAdmin?.password?.length > 0 && !validatePassword()) {
            setError('Passwords do not match')
            setRequestStatus({...requestStatus,loading: false})
            return
        }
        if(Object.keys(newAdmin?.permissions[0]).reduce((acc,curr) => acc || newAdmin?.permissions[0][curr],false) === false) {
            setError('Please select at least one permission')
            setRequestStatus({...requestStatus,loading: false})
            return
        }
        try{
            const editData = {permissions: newAdmin?.permissions}
            if(newAdmin?.password?.length > 0){
                editData.password = newAdmin?.password
            }
            const res = editAdmin(editData,editId)
            setRequestStatus({...requestStatus,loading: false,success: true})
            toast.success('Admin Updated Successfully')
            if(user?.user?._id === editId){
                const newUserData = {...user,user: {...user?.user,permissions: newAdmin?.permissions}}
                setUser(newUserData)
                localStorage.setItem('Mantra-Admin-User', JSON.stringify(newUserData))
            }
            window.location.reload()
        }
        catch(err){
            setError(err)
            setRequestStatus({...requestStatus,loading: false})
            toast.error('Something went wrong')
        }
    }

    const permissions = [
        {
            label: 'Super Admin',
            name: 'superAdmin',
            checked: newAdmin?.permissions && newAdmin?.permissions[0]?.superAdmin
        },
        {
            label: 'Mantra',
            name: 'mantra',
            checked: newAdmin?.permissions && newAdmin?.permissions[0]?.mantra
        },
        {
            label: 'Matricula',
            name: 'matricula',
            checked: newAdmin?.permissions && newAdmin?.permissions[0]?.matricula
        },
        {
            label: 'Courses',
            name: 'courses',
            checked: newAdmin?.permissions && newAdmin?.permissions[0]?.courses
        }
    ]

    const setPermissions = (e) => {
        if(e.target.checked){
            setNewAdmin({...newAdmin,permissions: [{...newAdmin?.permissions[0],[e.target.value]: true}]})
        }else{
            setNewAdmin({...newAdmin,permissions: [{...newAdmin?.permissions[0],[e.target.value]: false}]})
        }
    }

    const inputFields = [
        {
            label: 'Username',
            name: 'username',
            type: 'text',
            placeholder: 'Enter Username',
            value: newAdmin?.username,
            onChange: (e) => setNewAdmin({...newAdmin,username: e.target.value}),
            required: true,
            disabled: editId !== null
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'Enter Email',
            value: newAdmin?.email,
            onChange: (e) => setNewAdmin({...newAdmin,email: e.target.value}),
            required: true,
            disabled: editId !== null
        },
        {
            label: 'Password',
            name: 'password',
            type: showPasswords ? 'text' : 'password',
            placeholder: 'Enter Password',
            value: newAdmin?.password,
            onChange: (e) => setNewAdmin({...newAdmin,password: e.target.value}),
            required: editId === null
        },
        {
            label: 'Confirm Password',
            name: 'confirmPassword',
            type: showPasswords ? 'text' : 'password',
            placeholder: 'Confirm Password',
            value: newAdmin?.confirmPassword,
            onChange: (e) => setNewAdmin({...newAdmin,confirmPassword: e.target.value}),
            required: editId === null
        },
        {
            label: 'Show Passwords',
            name: 'showPasswords',
            type: 'checkbox',
            value: showPasswords,
            onChange: () => setShowPasswords(!showPasswords)
        }
    ]

    return {inputFields,permissions,error,setPermissions,submitNewAdminForm,submitEditAdminForm,requestStatus,editId}
}