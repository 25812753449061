import React from 'react'
import { FaUserCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../data/store';

function Header() {
    const [user, setUser] = useAtom(userAtom)

    const logout = () => {
        setUser({user: null, token: null})
        localStorage.removeItem('Mantra-Admin-User')
    }

  return (
    <div className='flex-row-start primary-palette p-4 sticky top-0 z-10'>
        <h1 className='self-center font-bold text-lg'>Mantra Admin</h1>
        <div>
            {user?.token === null ? <Link to={'/auth/login'} className='btn-secondary'>Login</Link> : 
            <div className='flex-row-start gap-2 items-center'>
                <FaUserCircle className='text-xl'/>
                <div className='self-center'>Hello, {user?.user?.username}</div>
                <button onClick={logout} className='btn-secondary'>Logout</button>
            </div>
            }
        </div>
    </div>
  )
}

export default Header