import React from 'react'
import { useAtom } from 'jotai'
import { addCourseAtom, editCourseAtom } from '../../data/store'
import Input from '../../../../components/Input/Input'
import {IoIosCloseCircle}  from 'react-icons/io'
import AddCourseLogic from './AddCourseLogic'
import File from '../File'

function AddCourseModal() {
    const [addCourse,setAddCourse] = useAtom(addCourseAtom)
    const [editCourse,setEditCourse] = useAtom(editCourseAtom)
    const {inputFields, submitNewCourse} = AddCourseLogic()  

    const displayText = editCourse !== null ? 'Edit' : 'Add'

    console.log(editCourse);

    return (
    addCourse && <section className='modal'>
        <div className='modal-content relative'>
            <div className='modal-header sticky top-0 bg-white border-b'>
                <button onClick={()=>{
                    setAddCourse(false)
                    setEditCourse(null)
                }} className='text-3xl right-0 m-2 absolute top-0'><IoIosCloseCircle/></button>
                <h2 className='heading'>{displayText} Course</h2>
            </div>
            <form onSubmit={submitNewCourse} className='modal-body'>
                <h3 className='heading text-xl border-b py-2 mb-2'>Basic Info</h3>
                <div className='grid grid-cols-2 gap-4'>
                    {inputFields.basicInfo.map((inputField,index)=>(
                        inputField.type === 'file' ? <File key={index} inputField={inputField}/> : <Input key={index} {...inputField}/> 
                    ))}
                </div>
                <h3 className='heading text-xl border-b py-2 mb-2'>Additional Details</h3>
                <div className='grid grid-cols-2 gap-4'>
                    {inputFields.moreInfo.map((inputField,index)=>(
                        <Input key={index} {...inputField}/> 
                    ))}
                </div>
                <h3 className='heading text-xl border-b py-2 mb-2'>Meta Description</h3>
                <div className='grid grid-cols-2 gap-4'>
                    {inputFields.metaDescription.map((inputField,index)=>(
                        <Input key={index} {...inputField}/> 
                    ))}
                </div>
                <button type='submit' className='btn-primary mt-4 w-full'>{displayText} Course</button>
            </form>
        </div>
    </section>
  )
}

export default AddCourseModal