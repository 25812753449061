import React, {useEffect, useState} from 'react'
import BackBtn from '../../../components/BackBtn'
import Error from '../../../components/Error'
import Input from '../../../components/Input/Input'
import { useAdminLogic } from './addAdminLogic'

function AddAdmin() {

    const {inputFields,permissions,error,setPermissions,submitNewAdminForm, submitEditAdminForm, requestStatus,editId} = useAdminLogic()

    const displayText = editId === null ? 'Add' : 'Edit'

  return (
    inputFields && <section className='flex-col-4'>
        <h1 className='heading'><BackBtn/> {displayText} Admin</h1>
        <form onSubmit={editId === null ? submitNewAdminForm : submitEditAdminForm} className='flex-col-4'>
            {inputFields.map((inputField,idx) => <Input key={idx} {...inputField}/>)}
            <div className='flex-row-start justify-start gap-4' onChange={setPermissions}>
                <label htmlFor="permission">Permissons:</label>
                {permissions.map((permission,idx) => <Input key={idx} label={permission.label} name={'permission'} checked={permission.checked} value={permission.name} type='checkbox'/>)}
            </div>
            {error !== null && <Error error={error}/>}
            <button type='submit' disabled={requestStatus?.loading} className='btn-primary'>{requestStatus?.loading ? 'Processing...' : `${displayText} Admin`}</button>
        </form>
    </section>
  )
}

export default AddAdmin