import React from 'react'
import { Outlet } from 'react-router-dom'
import AddCourseModal from './components/AddCourseModal/AddCourseModal'
import { useAtom } from 'jotai'
import { newCourseAtom, addCourseAtom } from './data/store'
import ReferralModal from './components/ReferralModal/ReferralModal'
import CouponModal from './components/CouponModal/CouponModal'

function Index() {
  const [newCourse, setNewCourse] = useAtom(newCourseAtom)
  const [addCourse, setAddCourse] = useAtom(addCourseAtom)

  return (
    <section className='pb-0'>
      <Outlet />
      <AddCourseModal />
      <ReferralModal />
      <CouponModal/>
    </section>
  )
}

export default Index