import { courseAxiosInstance } from '../../../../axios/axiosInstance'


export const editReferral = async (data) => {
    const res = await courseAxiosInstance.post("/private/courses/courses/referral/edit", {
        coin: data.coinsperref,
        discount: data.percentagediscount,
        refid: "6421c9ace7154dab4f15a79e"
    });
    return res.data;
}

