import React from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { addCourseAtom, editCourseAtom } from '../../data/store';

import {MdEdit} from 'react-icons/md'
import {AiFillDelete, AiFillEye} from 'react-icons/ai'
import {RiMenuAddFill} from 'react-icons/ri'

import { deleteCourseFromDB } from './CourseCardLogic';

function CourseCard({ course }) {
  const [addCourse, setAddCourse] = useAtom(addCourseAtom);
  const [editCourse, setEditCourse] = useAtom(editCourseAtom);

  const urlOrigin = window.location.origin

  return (
    <div  className="flex-1 lg:flex-none basis-[50%] xs:basis-[45%] sm:basis-[33%] md:basis-[20%] xl:basis-[18%]">
      <div className="flex flex-col justify-between w-full h-full gap-2 outline outline-1 outline-gray-300 overflow-hidden rounded-lg relative hover:shadow-lg hover:shadow-primary/15 transition-all">
        <img
          alt="Course Card Image"
          className="lg:min-h-[10rem] aspect-video object-cover w-full"
          src={course.featureImage}
        />
        {course?.isActive && (
          <p className="absolute top-0 left-0 p-2 bg-green-600 text-white uppercase rounded-br-xl text-xs sm:text-sm shadow-md font-bold tracking-wider">
            Active
          </p>
        )}
        <div className="flex flex-col gap-2 p-4 pb-6 relative h-full justify-between">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-bold">{course?.courseName}</h1>
            <p className="text-sm">{course?.courseDescription}</p>
            <p className="text-sm">{course?.instructorName}</p>
          </div>
          <div className='flex flex-row items-center justify-start gap-2 flex-wrap mt-auto'>
            <h4 className='text-green-600 font-bold text-xl'>&#8377; {course.slashedPrice || course.price}</h4>
            {course.standard && <p className="text-sm p-2 bg-blue-200 rounded-full">Class: {course?.standard}</p>}
            <p className="text-sm p-2 bg-blue-200 rounded-full">Instructor: {course?.instructorName}</p>
          </div>
          <div className="w-full flex justify-evenly items-center border-t mt-2 pt-4 text-lg">
            <Link title='See Live Course' target={'_blank'} className={`rounded-btn bg-yellow-500`} to={`${urlOrigin}/courses/${course?.courseSlug}`}>
              <AiFillEye/>
            </Link>
            <button
              onClick={()=>deleteCourseFromDB(course?.courseSlug)}
              title="Delete Course"
              className="rounded-btn bg-red-600"
            >
              <AiFillDelete />
            </button>
            <button
            onClick={()=>{
              setEditCourse(course)
              setAddCourse(true)
            }}
              title="Edit Course"
              className="bg-primary text-white font-bold p-4 uppercase hover:shadow-md focus:opacity-75 transition-all rounded-full shadow-md"
            >
              <MdEdit />
            </button>
            <Link to={`add/${course?.courseSlug}`}
              title="Add Modules"
              className="bg-purple-600 text-white font-bold p-4 uppercase hover:shadow-md focus:opacity-75 transition-all rounded-full shadow-md"
              
            >
              <RiMenuAddFill />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
