import axios from "../axios/axiosInstance";
import { useAtom } from "jotai";
import { userAtom } from "../data/store";

export const createAdmin = async (newAdmin) => {
   const { data } = await axios.post("/common/admin/add-admin", newAdmin);
    return data;
}

export const editAdmin = async (editedAdmin,editId) => {
    const { data } = await axios.put(`/common/admin/${editId}`, editedAdmin);
    return data;
}

export const deleteAdmin = async (adminId) => {
    const { data } = await axios.delete(`/common/admin/${adminId}`);
    return data;
}

export const loginAdmin = async (admin) => {
    const { data } = await axios.post("/common/admin/login", admin);
    return data;
}