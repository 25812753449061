import React from 'react'
import { useChapterLogic } from './AddChapterLogic'
import {AiFillDelete, AiOutlineVideoCameraAdd} from 'react-icons/ai'
import Input from '../../../../components/Input/Input'

import { MdModeEditOutline } from 'react-icons/md'

function AddCourseChapter() {
    const {data, error, addNewChapter, setAddNewChapter, deleteChapter, chapterInputFields, addNewChapterHandler, toggleChapterPreview, adding, editId, setEditId, videoField, uploadVideo, uploading} = useChapterLogic()

    if(error) return <h1>Error</h1>
    if(!data) return <h1>Loading...</h1>

  return (
    <section className='border-l h-full overflow-auto'>
        <div className='flex-row-start '>
            <h2 className='heading w-full text-2xl pb-2 border-b'>Module Chapters</h2>
            <button onClick={()=> setAddNewChapter(!addNewChapter)} title='Add Chapter' className='rounded-btn'><AiOutlineVideoCameraAdd/></button>
        </div>
        {addNewChapter && 
            <form onSubmit={addNewChapterHandler} className='modal-body flex-col-4 sticky -top-4 bg-white'>
                <h3 className='heading text-xl'>{editId === null ? 'Add' : 'Edit'} Chapter</h3>
                {chapterInputFields.map(inputField => <Input {...inputField}/>)}
                <p className='inline-flex w-full items-center gap-2'><hr className='w-full'/>OR<hr className='w-full'/></p>
                <Input {...videoField}/>
                <button disabled={uploading} onClick={uploadVideo} className='primary-palette p-2 rounded font-bold'>Upload{uploading && 'ing...'} Video</button>
                <button onClick={addNewChapterHandler} disabled={adding} type='submit' className='btn btn-primary'>{editId === null ? 'Add' : 'Edit'}{adding && 'ing...'} Chapter</button>
            </form>
        }
        <div className='flex-col-4 gap-2 mt-4'> 
        {data.length === 0 && <p className='text-muted'>Go ahead and add the first chapter!</p>}
        {data.map((chap,idx) => (
            <div className='flex-row-start rounded-lg overflow-hidden shadow-sm border hover:text-' key={chap._id}>
                <div className='flex-col-4 p-4 w-full'>
                    <div className='flex-row-start'>
                        <h3 className='text-lg'>{idx+1}. {chap.title} {chap.chapterSlug}</h3>
                        <button onClick={()=>toggleChapterPreview({chap,preview: !chap.preview})} className={`${chap.preview ? 'bg-green-600 text-white' : 'text-green-600 outline outline-1'} text-sm p-2 rounded`}>{chap.preview ? 'Disable' : 'Enable'} Preview</button>
                    </div>
                    <div className='flex-row-start justify-end gap-2'>
                        <button
                          onClick={()=>deleteChapter(chap.chapterSlug)}
                          title="Delete Chapter"
                          className="rounded-btn bg-red-600 text-sm"
                        >
                          <AiFillDelete />
                        </button>
                        <button onClick={()=>setEditId(chap.chapterSlug)} title='Edit Chapter' className='rounded-btn text-sm'>
                            <MdModeEditOutline />
                        </button>
                    </div>
                </div>
                <iframe src={chap.video_link} title="youtube-video-player" className='w-36 object-contain aspect-video' ></iframe>
            </div>
        ))}
        </div>
    </section>
  )
}

export default AddCourseChapter