import React from "react";
import SidebarLogic from "./SidebarLinks";
import { NavLink } from "react-router-dom";



function Sidebar() {
  
  const {links} = SidebarLogic();

  return (
    <div className="p-4 border-r h-screen flex-col-4 w-full basis-[10%] text-center pt-16">
      {links.map((link, index) => (
        link?.show &&
        <NavLink
          className={({ isActive }) =>
            isActive ? "active" : ""
          }
          key={link.id}
          to={link.url}
        >
          {link.text}
        </NavLink>
      ))}
    </div>
  );
}

export default Sidebar;
