import React, { useState } from 'react'
import { RiMenuAddFill } from 'react-icons/ri'
import {RxCross1} from 'react-icons/rx'
import { Link, NavLink, Outlet } from 'react-router-dom'
import Input from '../../../components/Input/Input'
import { useCourseModules } from './CourseModuleLogic'
import {TbExternalLink} from 'react-icons/tb'
import { AiFillDelete } from 'react-icons/ai'
import { MdModeEditOutline } from 'react-icons/md'
import {AiFillEye} from 'react-icons/ai'

function AddCourse() {
  const {data,error, newModuleInputFields, addNewModule, deleteModule, editId, setEditId, addModule, setAddModule, updateCourseStatus} = useCourseModules()
  
  if(error) return <div>{error}</div>
  if(!data) return <div>Loading...</div>
  return (
    <section className='flex-row-start h-[88vh] overflow-auto p-0'>
      <div className='flex-col-4 w-full pr-4 overflow-auto'>
        <div className='flex-row-start'>
          <h1 className='heading'>{data?.course?.courseName}</h1>
          <button onClick={()=>updateCourseStatus(!data?.course?.isActive)} className={`${data?.course?.isActive ? 'bg-green-600 text-white' : 'btn-secondary text-green-600 outline outline-1'} self-center mt-2 p-2 rounded`}>{data.course?.isActive ? 'Deactivate' : 'Activate'}</button>
        </div>
        <p>Class: {data?.course?.standard}. {data?.course?.goal && `Goal: ${data.course.goal}`}</p>
        <p>{data?.course?.courseDescription}</p>
        <div className='flex-row-start'>
          <h2 className='heading text-2xl w-full pb-2 border-b'>Modules</h2>
          <button onClick={()=>setAddModule(!addModule)} title="Add Module" className="rounded-btn">
            {!addModule ? <RiMenuAddFill /> : <RxCross1 />}
          </button>
        </div>
        {addModule && <form onSubmit={addNewModule} className='flex-row-start gap-1'>
            {newModuleInputFields?.map(field => <Input {...field}/>) }
            <button className='btn btn-primary self-end'>{editId === null ? 'Add' : 'Edit'} Module</button>
          </form>}
        <div className=''>
        {data.modules.length === 0 && <p className='text-muted'>You haven't added any modules yet! Go ahead and add one.</p>}
        {data.modules?.map((module) => (
          <div className='flex-row-start mb-2'>
            <NavLink className={({isActive}) => `flex-row-start justify-start items-center gap-2 transition-all mb-2 active:text-primary w-max ${isActive && 'active sublink-active'}`} to={`module/${module._id}/${module?.moduleSlug}`} key={module._id}>
              <h2 className='peer'>{module.title}</h2>
              <TbExternalLink className='opacity-0 peer-hover:opacity-100'/> 
            </NavLink>
            <div className='flex-row-start items-end w-min gap-2'>
              <NavLink className={`rounded-btn p-2 bg-purple-600`} to={`module/${module._id}/${module?.moduleSlug}`} key={module._id}>
                <AiFillEye/>
              </NavLink>
              <button onClick={()=>deleteModule(module?.moduleSlug)} title='Delete Module' className='rounded-btn p-2 bg-red-600'><AiFillDelete /></button>
              <button onClick={()=>setEditId(module?.moduleSlug)} title='Edit Module' className='rounded-btn p-2'><MdModeEditOutline /></button>
            </div>
          </div>
        ))}
        </div>
      </div>
      <Outlet/>
    </section>
  )
}

export default AddCourse