import { atom } from "jotai";

export const userAtom = atom( JSON.parse(localStorage.getItem('Mantra-Admin-User')) || {
    user: null,
    token: null,
})
export const adminAtom = atom([])

export const linksAtom = atom(get => [
    {
        id: 1,
        url: '/',
        text: 'Home',
        show: get(userAtom)?.token !== null,
    },
    {
        id: 2,
        url: '/admins',
        text: 'Admins',
        name: 'superAdmin',
        show: get(userAtom)?.token !== null &&  get(userAtom)?.user?.permissions[0].superAdmin === true,
    },
    {
        id: 3,
        url: '/courses',
        text: 'Course',
        name: 'courses',
        show: get(userAtom)?.token !== null && get(userAtom)?.user?.permissions[0].courses === true,
    },
    {
        id: 4,
        url: '/mantra',
        text: 'Mantra',
        name: 'mantra',
        show: get(userAtom)?.token !== null && get(userAtom)?.user?.permissions[0].mantra === true,
    },
    {
        id: 5,
        url: '/matricula',
        text: 'Matricula',
        name: 'matricula',
        show: get(userAtom)?.token !== null && get(userAtom)?.user?.permissions[0].matricula === true,
    },
])