import useSwr from 'swr';

import axios,{courseAxiosInstance as courseAxios} from '../axios/axiosInstance';

const fetcher = async url => await axios.get(url).then(res => res.data);
const courseFetcher = async url => await courseAxios.get(url).then(res => res.data);

const useSwrData = address => {
  const { data, error } = useSwr(address, fetcher);
  return { data, error };
};

const useCourseSwrData = address => {
  const { data, error } = useSwr(address, courseFetcher);
  return { data, error };
};

export { useSwrData, useCourseSwrData, fetcher, courseFetcher };
