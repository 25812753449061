import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../../components/Input/Input'
import { AdminLogic } from './AdminLogic'
import {BiErrorCircle} from 'react-icons/bi'
import {MdEdit} from 'react-icons/md'

import { useAtom } from 'jotai'
import { adminAtom } from '../../../data/store'

function Admins() {
    const {admins,deleteAdminFromDB} = AdminLogic()

    const [addAdmin,setAllAdmin] =  useAtom(adminAtom)

    useEffect(() => {
        if(admins?.data)
            setAllAdmin(admins.data)
    },[admins])

    const [filteredList,setFilteredList] = useState(admins?.data)
    const [search,setSearch] = useState('')

    useEffect(() => {
        if(search.length > 0){
            setFilteredList(admins?.data?.filter((admin) => admin.username.toLowerCase().includes(search.toLowerCase())))
        }else{
            setFilteredList(admins?.data)
        }
    },[search,admins])

    return (
        <section className='flex-col-4'>
            <div className='flex-row-start gap-4'>
                <h1 className='heading'>Admins</h1>
                <Link to='/admins/add-admin' className='btn-secondary outline outline-2 outline-primary'>Add Admin</Link>
            </div>
            <Input value={search} onChange={(e)=>setSearch(e.target.value)} name='Search' placeholder={'Search List of Admins...'}/>
            <table>
                <thead>
                    <tr>
                        <th>Sl.</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Permissions</th>
                        <th>Delete Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredList?.map((admin,idx) => (
                        <tr key={admin?._id} className='relative'>
                            <td>{idx + 1}.</td>
                            <td>{admin.username}</td>
                            <td>{admin.email}</td>
                            <td className='relative'>
                                <BiErrorCircle className='hover:text-primary peer'/>
                                <p className='hidden peer-hover:inline-flex absolute self-center bottom-10 bg-white p-2 shadow-md rounded-xl outline outline-1 outline-gray-200 gap-1 text-sm'>{Object.keys(admin?.permissions[0]).filter((permission) => admin?.permissions[0][permission] === true).map((permission,idx) => <span className='p-1 px-2 bg-gray-300 rounded-full' key={idx}>{admin?.permissions[0][permission] === true ? permission : null}</span>)}</p>
                            </td>
                            <td><button onClick={()=>deleteAdminFromDB(admin?._id)} className='btn-secondary outline outline-1 outline-primary active:bg-red-600 active:text-white'>Delete Admin</button></td>
                            <td><Link to={`add-admin?id=${admin?._id}`}><MdEdit title='Edit'/></Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
      )
}

export default Admins