import React, { useState } from 'react'
import { useAtom } from 'jotai'
import { referralState } from '../../data/store'
import { IoIosCloseCircle } from 'react-icons/io'
import { useCourseSwrData } from '../../../../swr/swrWrapper'
import { editReferral } from './ReferralModalLogic'
import { toast } from 'react-toastify'

const ReferralModal = () => {
    // const {data}= await axios.post('/private/courses/courses/referral/edit',)
    const { data: res, error } = useCourseSwrData('/private/courses/courses/referral/get')
    console.log(res);

    const [openRefrral, setOpen] = useAtom(referralState)
    const [referinfo, setReferInfo] = useState({
        percentagediscount: res?.info?.percentageDiscount,
        coinsperref: res?.info?.coinsPerReferral
    })
    const handleEdit = (e) => {
        e.preventDefault()
        editReferral(referinfo)
        toast.success('Referral Admin added')
        setOpen(false)
    }
    return (
        openRefrral && <section className='modal'>
            <div className='modal-content relative'>
                <div className='modal-header sticky top-0 bg-white border-b'>
                    <button onClick={() => {
                        setOpen(false)
                    }} className='text-3xl right-0 m-2 absolute top-0'><IoIosCloseCircle /></button>
                    <h2 className='heading'>Edit Referral</h2>
                </div>
                <form className='modal-body' onSubmit={handleEdit}>
                    <h3 className='heading text-xl border-b py-2 mb-2'>Coins per Referral:-{res?.info?.coinsPerReferral} and discount:-{res?.info?.percentageDiscount}</h3>
                    <label htmlFor="">Per Course Discount from Referral</label>
                    <input type="number" className='input-field' value={referinfo.percentagediscount} onChange={e => setReferInfo({ ...referinfo, percentagediscount: e.target.value })} />
                    <label htmlFor="">Coins Distribution from referral</label>
                    <input type="number" className='input-field' value={referinfo.coinsperref} onChange={e => setReferInfo({ ...referinfo, coinsperref: e.target.value })} />
                    <button type='submit' className='btn-primary'>Edit Referral</button>
                </form>
            </div>
        </section>
    )
}

export default ReferralModal