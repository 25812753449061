import { atom } from "jotai";

export const addCourseAtom = atom(false)

export const referralState = atom(false)
export const couponState = atom(false)

export const editCourseAtom = atom(null)

export const newCourseAtom = atom({
    courseName: '',
    courseCategory: '',
    courseDescription: '',
    courseSlug: '',
    standard: '',
    goal: '',
    duration: '',
    difficulty: 'Beginner',
    price: 'Free',
    slashedPrice: '',
    offerEndDate: '',
    instructorName: '',
    metaDescription: '',
    metaKeywords: '',
    featureImage: '',
    file: '',
    info1: '',
    info2: '',
    info3: '',
    info4: '',
    perk: ''
})