import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addModuleToDB, deleteModuleFromDB, editModuleInDB, toggleCourseStatus } from "../../../api/course";
import { useCourseSwrData } from "../../../swr/swrWrapper";

export const useCourseModules = () => {
  const { slug } = useParams();
  const { data, error } = useCourseSwrData(`/private/courses/${slug}/modules`);

  const [addModule,setAddModule] = useState(false)
  
  const [editId,setEditId] = useState(null);
  
  const changeModuleInputValue = ({name,value}) => {
    setNewModuleInputFields(prev => prev.map((field) => field.name === name ? {...field,value} : field));
  }
  
  const defaultInputFields = [{
    label: "Module Name",
    name: "new-module",
    value: '',
    type: "text",
    onChange: (e) => {
      const {name,value} = e.target;
      changeModuleInputValue({name,value});
    },
    required: true,
    placeholder: "Enter Module Name",
  }];
  
  const [newModuleInputFields, setNewModuleInputFields] = useState(defaultInputFields);

  const updateCourseStatus = async (status) => {
    const ans = window.confirm(`Are you sure you want to ${status ? 'activate' : 'deactivate'} this course?`);
    if(!ans) return;
    try {
      const res = await toggleCourseStatus({courseSlug: slug, isActive: status, courseName: data?.course?.courseName});
      toast.success(res.message);
      window.location.reload();
    }catch(err){
      console.log(err);
      toast.error(err.response.data.message || err.message || 'Something went wrong');
    }
  }


  const addNewModule = async (e) => {
        e.preventDefault();
        const newModuleName = newModuleInputFields.find(field => field.name === 'new-module').value;
        try {
            const res = (editId === null) ? await addModuleToDB(newModuleName, data?.course?._id, slug) : await editModuleInDB({moduleTitle: newModuleName, moduleSlug: editId, courseSlug: slug});
            toast.success(res.message);
            window.location.reload();
        }catch(err){
            console.log(err);
            toast.error(err.response.data.message || err.message || 'Something went wrong');
        }
    }

    useEffect(() => {
      if(!addModule) {
          setEditId(null);
          setNewModuleInputFields(defaultInputFields)
      }
  },[addModule])

    useEffect(() => {
        if(editId !== null) {
          setAddModule(true);
          const targetModule = data.modules.find(module => module.moduleSlug === editId);
          console.log(targetModule);
          setNewModuleInputFields(prev => {
            console.log(prev.map(field => field.name === 'new-module' ? {...field,value: targetModule.title} : field))
            setNewModuleInputFields(prev.map(field => field.name === 'new-module' ? {...field,value: targetModule.title} : field))
          });
        }
    },[editId])

    const deleteModule = async (moduleSlug) => {
        const ans = window.confirm('Are you sure you want to delete this module?');
        if(!ans) return;
        try {
            const res = await deleteModuleFromDB(moduleSlug);
            console.log(res);
            toast.success('Module Deleted');
            window.location.reload();
        }catch(err){
            console.log(err);
            toast.error(err);
        }
      }


  return { data: data, error, newModuleInputFields, setNewModuleInputFields, addNewModule, deleteModule, editId, setEditId, addModule, setAddModule, updateCourseStatus };
};
