import React from 'react'
import { useNavigate } from 'react-router-dom'
import {IoArrowBackCircle} from 'react-icons/io5'

function BackBtn() {
    const navigate = useNavigate()
  return (
    <button title='Go Back' className='self-center' onClick={()=>navigate(-1)}><IoArrowBackCircle className='text-4xl text-primary'/></button>
  )
}

export default BackBtn