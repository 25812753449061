import { useAtom } from "jotai"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { addCourse, updateCourse } from "../../../../api/course"
import { newCourseAtom, editCourseAtom } from ".././../data/store"

export default function AddCourseLogic() {
    const [newCourse, setNewCourse] = useAtom(newCourseAtom)
    const [editCourse, setEditCourse] = useAtom(editCourseAtom)

    useEffect(() => {
        if (editCourse) {
            setNewCourse({ ...editCourse, price: Number(editCourse.price) > 0 ? 'paid' : 'free', priceValue: editCourse.price === 'Free' ? '' : editCourse.price })
        }
        else {
            setNewCourse({
                courseName: '',
                courseCategory: '',
                courseDescription: '',
                courseSlug: '',
                standard: '',
                goal: '',
                duration: '',
                difficulty: 'Beginner',
                price: 'Free',
                slashedPrice: '',
                offerEndDate: '',
                instructorName: 'Matricula',
                metaDescription: '',
                metaKeywords: '',
                featureImage: '',
                file: '',
                info1: '',
                info2: '',
                info3: '',
                info4: '',
                perk: ''
            })
        }
    }, [editCourse])

    const inputFields = {
        basicInfo: [
            {
                key: 'courseName',
                label: 'Course Name',
                type: 'text',
                placeholder: 'Enter Course Name',
                required: true,
                value: newCourse.courseName,
                onChange: (e) => setNewCourse({ ...newCourse, courseName: e.target.value })
            },
            {
                key: 'courseCategory',
                label: 'Course Category',
                type: 'text',
                placeholder: 'Enter Course Category',
                required: true,
                value: newCourse.courseCategory,
                onChange: (e) => setNewCourse({ ...newCourse, courseCategory: e.target.value })
            },
            {
                key: 'courseDescription',
                label: 'Course Description',
                type: 'description',
                placeholder: 'Enter Course Description',
                required: false,
                value: newCourse.courseDescription,
                onChange: (e) => setNewCourse({ ...newCourse, courseDescription: e.target.value })
            },
            {
                key: 'standard',
                label: 'Class',
                type: 'select',
                options: Array.from({ length: 12 }, (v, k) => k + 1).map((v) => ({ value: v, label: `Class ${v}` })),
                placeholder: 'Enter class',
                required: true,
                value: newCourse.standard,
                onChange: (e) => setNewCourse({ ...newCourse, standard: e.target.value })
            },
            {
                key: 'goal',
                label: 'Goal',
                type: 'text',
                placeholder: 'Enter Goal',
                required: false,
                value: newCourse.goal,
                onChange: (e) => setNewCourse({ ...newCourse, goal: e.target.value })
            },
            {
                key: 'duration',
                label: 'Course Duration (in Hrs)',
                type: 'number',
                placeholder: 'Enter Course Duration',
                required: true,
                value: newCourse.duration,
                onChange: (e) => setNewCourse({ ...newCourse, duration: e.target.value })
            },
            {
                key: 'price',
                label: 'Course Price',
                type: 'select',
                options: [
                    { value: 'free', label: 'Free' },
                    { value: 'paid', label: 'Paid' }
                ],
                placeholder: 'Enter Course Price',
                required: true,
                value: newCourse.price,
                onChange: (e) => setNewCourse({ ...newCourse, price: e.target.value }),
            },
            {
                key: 'priceValue',
                label: 'Course Price Value',
                type: 'number',
                placeholder: 'Enter Course Price Value',
                required: newCourse.price !== 'free',
                show: newCourse.price === 'paid' || newCourse.price > 0,
                value: newCourse.priceValue,
                onChange: (e) => setNewCourse({ ...newCourse, price: e.target.value, priceValue: e.target.value })
            },
            {
                key: 'slashedPrice',
                label: 'Course Slashed Price',
                type: 'number',
                placeholder: 'Enter Course Slashed Price',
                required: false,
                show: newCourse.price === 'paid' || newCourse.price > 0,
                value: newCourse.slashedPrice,
                onChange: (e) => setNewCourse({ ...newCourse, slashedPrice: e.target.value }),
            },
            {
                key: 'offerEndDate',
                label: 'Offer End Date',
                type: 'date',
                placeholder: 'Enter Offer End Date',
                required: newCourse.slashedPrice !== '' && Number(newCourse.slashedPrice) > 0,
                show: newCourse.price === 'paid' || newCourse.price > 0,
                value: newCourse.offerEndDate,
                onChange: (e) => setNewCourse({ ...newCourse, offerEndDate: e.target.value }),
            },
            {
                key: 'difficulty',
                label: 'Course Difficulty',
                type: 'select',
                options: [
                    { value: 'beginner', label: 'Beginner' },
                    { value: 'intermediate', label: 'Intermediate' },
                    { value: 'advanced', label: 'Advanced' }
                ],
                placeholder: 'Enter Course Difficulty',
                required: true,
                value: newCourse.difficulty,
                onChange: (e) => setNewCourse({ ...newCourse, difficulty: e.target.value }),
            },
            {
                key: 'instructorName',
                label: 'Instructor Name',
                type: 'text',
                placeholder: 'Enter Instructor Name',
                required: true,
                value: newCourse.instructorName,
                onChange: (e) => setNewCourse({ ...newCourse, instructorName: e.target.value })
            },
            {
                key: 'featureImage',
                label: 'Feature Image',
                type: 'file',
                placeholder: 'Enter Feature Image',
                required: false,
                value: newCourse.file,
                onChange: (e) => {
                    setNewCourse({ ...newCourse, featureImage: e.target.files[0], file: e.target.value })
                }
            }
        ],
        moreInfo: [
            {
                key: 'info1',
                label: 'Course Info 1',
                type: 'description',
                placeholder: 'Enter Course Info 1',
                required: false,
                value: newCourse.info1,
                onChange: (e) => setNewCourse({ ...newCourse, info1: e.target.value })
            },
            {
                key: 'info2',
                label: 'Course Info 2',
                type: 'description',
                placeholder: 'Enter Course Info 2',
                required: false,
                value: newCourse.info2,
                onChange: (e) => setNewCourse({ ...newCourse, info2: e.target.value })
            },
            {
                key: 'info3',
                label: 'Course Info 3',
                type: 'description',
                placeholder: 'Enter Course Info 3',
                required: false,
                value: newCourse.info3,
                onChange: (e) => setNewCourse({ ...newCourse, info3: e.target.value })
            },
            {
                key: 'info4',
                label: 'Course Info 4',
                type: 'description',
                placeholder: 'Enter Course Info 4',
                required: false,
                value: newCourse.info4,
                onChange: (e) => setNewCourse({ ...newCourse, info4: e.target.value })
            },
            {
                key: 'perk',
                label: 'Course Perk',
                type: 'description',
                placeholder: 'Enter Course Perk',
                required: false,
                value: newCourse.perk,
                onChange: (e) => setNewCourse({ ...newCourse, perk: e.target.value })
            }
        ],
        metaDescription: [
            {
                key: 'courseSlug',
                label: 'Course Slug',
                type: 'text',
                placeholder: 'Enter Course Slug',
                required: false,
                value: newCourse.courseSlug,
                onChange: (e) => setNewCourse({ ...newCourse, courseSlug: e.target.value })
            },
            {
                key: 'metaDescription',
                label: 'Course Meta Description',
                type: 'description',
                placeholder: 'Enter Course Meta Description',
                required: false,
                value: newCourse.metaDescription,
                onChange: (e) => setNewCourse({ ...newCourse, metaDescription: e.target.value })
            },
            {
                key: 'metaKeywords',
                label: 'Course Meta Keywords',
                type: 'text',
                placeholder: 'Enter Course Meta Keywords',
                required: false,
                value: newCourse.metaKeywords,
                onChange: (e) => setNewCourse({ ...newCourse, metaKeywords: e.target.value })
            }
        ],
    }

    const submitNewCourse = async (e) => {
        e.preventDefault();
        console.log(newCourse);
        try {
            const res = editCourse === null ? await addCourse(newCourse) : await updateCourse(newCourse);
            console.log(res);
            toast.success(res.message);
            window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message || error.message || 'Something went wrong');
        }
    }

    return { inputFields, submitNewCourse }
}