import React, { useState } from 'react'
import { useAtom } from 'jotai'
import { couponState } from '../../data/store'
import { IoIosCloseCircle } from 'react-icons/io'
import { useCourseSwrData } from '../../../../swr/swrWrapper'
import { toast } from 'react-toastify'
import { editCoupon } from './CouponLogic'

const CouponModal = () => {
    const { data: res, error } = useCourseSwrData('/private/courses/courses/coupon/get')
    const [openCoupon, setOpenCoupon] = useAtom(couponState)
    const [newCoupon, setCoupon] = useState({
        coupon: '',
        discountoncoupon: 0
    })
    const handleEdit = (e) => {
        e.preventDefault()
        editCoupon(newCoupon)
        toast.success('Coupon code modified')
        setOpenCoupon(false)
    }
    return (
        openCoupon && <section className='modal'>
            <div className='modal-content relative'>
                <div className='modal-header sticky top-0 bg-white border-b'>
                    <button onClick={() => {
                        setOpenCoupon(false)
                    }} className='text-3xl right-0 m-2 absolute top-0'><IoIosCloseCircle /></button>
                    <h2 className='heading'>Edit Coupon</h2>
                </div>

                <form className='modal-body' onSubmit={handleEdit}>
                    <h3 className='heading text-xl border-b py-2 mb-2'>Existing code:-{res?.info?.coupon} and discount:-{res?.info?.discount}</h3>
                    <label htmlFor="">New Coupon code *in caps</label>
                    <input type="text" className='input-field' value={newCoupon.coupon} onChange={e => setCoupon({ ...newCoupon, coupon: e.target.value })} />
                    <label htmlFor="">Discount on coupon</label>
                    <input type="number" className='input-field' value={newCoupon.discountoncoupon} onChange={e => setCoupon({ ...newCoupon, discountoncoupon: e.target.value })} />
                    <button type='submit' className='btn-primary'>Update Code</button>
                </form>
            </div>
        </section>
    )
}

export default CouponModal