import { toast } from "react-toastify";
import { deleteAdmin } from "../../../api/admin";
import { useSwrData } from "../../../swr/swrWrapper";
import { useAtom } from "jotai";
import { userAtom } from "../../../data/store";
import { useEffect } from "react";



export const AdminLogic = () => {
    const { data, error } = useSwrData("/common/admin");
    const [user, setUser] = useAtom(userAtom);
    
    const deleteAdminFromDB = async (adminId) => {
        const confirm = window.confirm("Are you sure you want to delete this admin?");
        if(confirm){
            try {
                const res = deleteAdmin(adminId);
                toast.success("Admin Deleted Successfully");
                window.location.reload();
            } catch (err) {
                toast.error("Something went wrong");
            }
        }
        else {
            return;
        }
    };

    return { admins: data, error, deleteAdminFromDB };
}