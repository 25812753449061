import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteChapterFromDB, addChapterToDB, editChapterInDB } from "../../../../api/course";
import { getYtEmbedLink } from "../../../../api/getYouTubeEmbedLink";
import { uploadImageToCloud } from "../../../../api/imageUpload";
import { useCourseSwrData } from "../../../../swr/swrWrapper";

export const useChapterLogic = () => {
    const { moduleSlug, moduleId, slug } = useParams();
    const {data,error} = useCourseSwrData(`/private/courses/${slug}/module/${moduleSlug}/chapters`)
    console.log(data);

    const [addNewChapter,setAddNewChapter] = useState(false);
    const [adding,setAdding] = useState(false);

    const [editId,setEditId] = useState(null);
    const [videoFile,setVideoFile] = useState(null);
    const [videoFileName,setVideoFileName] = useState(null);
    const [uploading, setUploading] = useState(false)

    const uploadVideo = async (e) => {
        e.preventDefault();
        setUploading(true);
        if (videoFile) {
            const formData = new FormData();
            formData.append("file", videoFile);
            formData.append("id", "matricula-courses");
            var allowedExtensions = /(\.mp4|\.MP4|\.mov|\.MOV)$/i;
            if(!allowedExtensions.exec(videoFile.name)) {
                toast.error('Only .mp4, .mov files are allowed');
                return;
            }
            else {
                try{
                    console.log(formData);
                    const res = await uploadImageToCloud(formData)
                    toast.success('File Uploaded Successfully');
                    console.log(res);
                    changeChapterInputValue({name:'chapterVideoLink',value:res.data.Location});
                }
                catch(err){
                    toast.error('File Upload Failed');
                    console.log(err)
                }
            }
        }
        else {
            toast.error('Please select a file first');
        }
        setUploading(false)
    }


    const changeChapterInputValue = ({name,value}) => {
        setChapterInputFields(prev => prev.map((field) => field.name === name ? {...field,value} : field));
    }

    const defaultInputFields = [
        {   
            label: 'Chapter Name',
            type: 'text',
            name: 'chapterName',
            value: '',
            placeholder: 'Enter Chapter Name',
            required: true,
            show: true,
            onChange: (e) => {
                const {name,value} = e.target;
                changeChapterInputValue({name,value});
            }
        },
        {
            label: 'Chapter Video Link',
            type: 'text',
            name: 'chapterVideoLink',
            value: '',
            placeholder: 'Enter Chapter Video Link',
            required: true,
            show: true,
            onChange: (e) => {
                const {name,value} = e.target;
                changeChapterInputValue({name,value});
            }
        },
    ]

    const videoField = {
        label: 'Upload Video File',
        type: 'file',
        required: false,
        value: videoFileName,
        onChange: (e)=>{
            const fileName = e.target.value;
            const file = e.target.files[0];
            if(file) {
                setVideoFile(file);
                setVideoFileName(fileName);
            }
        }
    }

    const [chapterInputFields,setChapterInputFields] = useState(defaultInputFields);

    const addNewChapterHandler = async (e) => {
        e.preventDefault();
        setAdding(true);
        const chapterName = chapterInputFields.find(field => field.name === 'chapterName').value;
        const chapterVideoLink = chapterInputFields.find(field => field.name === 'chapterVideoLink').value;
        // if(!chapterVideoLink.includes('youtube.com')) {
        //     toast.error('Please enter a valid youtube video link');
        //     setAdding(false);
        //     return
        // }
        try {
            const newChapterVideoLink = chapterVideoLink.includes('youtube.com') ? await getYtEmbedLink(chapterVideoLink) : chapterVideoLink;
            const res = (editId === null) ? await addChapterToDB({chapterName,chapterVideoLink: newChapterVideoLink,moduleSlug, courseSlug: slug, module: moduleId}) : await editChapterInDB({chapterName,chapterVideoLink: newChapterVideoLink,moduleSlug, chapterSlug: editId, courseSlug: slug});
            console.log(res);
            toast.success(res.message);
            window.location.reload();
        }catch(err){
            console.log(err);
            setAdding(false);
            toast.error(err?.response?.data?.message || err?.message || 'Something went wrong');
        }
        setAdding(false);
    }

    const deleteChapter = async (chapterSlug) => {
        const ans = window.confirm('Are you sure you want to delete this chapter?');
        if(!ans) return;
        console.log(data);
        try {
            const res = await deleteChapterFromDB(moduleSlug,chapterSlug);
            console.log(res);
            toast.success('Chapter Deleted');
            window.location.reload();
        }catch(err){
            console.log(err);
            toast.error(err);
        }
    }

    const toggleChapterPreview = async ({chap, preview}) => {
        const ans = window.confirm(`Are you sure you want to toggle this chapter preview?`);
        if(!ans) return;
        try{
            const res = await editChapterInDB({chapterName: chap.title,chapterVideoLink: chap.video_link,moduleSlug: chap.moduleSlug, chapterSlug: chap.chapterSlug, preview, courseSlug: slug})
            toast.success('Chapter Preview Toggled');
            window.location.reload();
        }
        catch(err) {
            console.log(err);
            toast.error(err);
        }
    }

            

    useEffect(() => {
        if(!addNewChapter) {
            setEditId(null);
            setChapterInputFields(defaultInputFields)
        }
    },[addNewChapter])

    useEffect(()=> {
        if(editId !== null) {
            setAddNewChapter(true);
            const chapter = data.body.find(chapter => chapter.chapterSlug === editId);
            setChapterInputFields([
                {   
                    label: 'Chapter Name',
                    type: 'text',
                    name: 'chapterName',
                    value: chapter.title,
                    placeholder: 'Enter Chapter Name',
                    required: true,
                    show: true,
                    onChange: (e) => {
                        const {name,value} = e.target;
                        changeChapterInputValue({name,value});
                    }
                },
                {
                    label: 'Chapter Video Link',
                    type: 'text',
                    name: 'chapterVideoLink',
                    value: chapter.video_link,
                    placeholder: 'Enter Chapter Video Link',
                    required: true,
                    show: true,
                    onChange: (e) => {
                        const {name,value} = e.target;
                        changeChapterInputValue({name,value});
                    }
                },
            ])
        }
    },[editId])

    return { moduleSlug, data: data?.body, error, addNewChapter, setAddNewChapter, addNewChapterHandler, adding, deleteChapter,toggleChapterPreview, chapterInputFields, setChapterInputFields, setEditId, editId, videoField, uploadVideo, uploading };
}