import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../../../api/admin";
import { userAtom } from "../../../data/store";

export default function LoginLogic() {
    const [user, setUser] = useAtom(userAtom);

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        if (user.user !== null && user.user !== undefined && user.token !== null && user.token !== undefined) {
            navigate('/')
        }
    }, [user, navigate])
    
    const inputFields = [
        {
            label: "Email",
            type: "email",
            name: "email",
            value: email,
            onChange: (e) => setEmail(e.target.value),
            required: true,
        },
        {
            label: "Password",
            type: "password",
            name: "password",
            value: password,
            onChange: (e) => setPassword(e.target.value),
            required: true,
        },
    ];

    const loginUser = async (e) => {
        e.preventDefault();
        try{
            const res = await loginAdmin({email,password})
            setUser(res.data)
            localStorage.setItem('Mantra-Admin-User',JSON.stringify(res.data))
            navigate('/')
        }
        catch(error){
            alert(error.response && error.response.data.message
                ? error.response.data.message
                : error.message)
        }
    };


    return {
        user,
        setUser,
        inputFields,
        loginUser,
    };
}