import React from 'react'
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom'
import Header from './components/header/Header'
import Sidebar from './components/sidebar/Sidebar'
import AddAdmin from './sections/Admin/addAdmin/AddAdmin'
import Admins from './sections/Admin/Admins/Admins'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import Login from './sections/User Auth/Login/Login'
import Auth from './sections/User Auth/Auth'

import { useAtom } from 'jotai'
import { userAtom } from './data/store'
import Home from './sections/Home/Home'
import Course from './sections/Matricula Courses/index'
import CourseHome from './sections/Matricula Courses/AllCourse/Course'
import CourseModules from './sections/Matricula Courses/AddCourseModules.js/CourseModules'
import AddCourseChapter from './sections/Matricula Courses/AddCourseModules.js/AddCourseChapter/AddCourseChapter'


function App() {
  const [user, setUser] = useAtom(userAtom)

  return (
    <Router>
      <ToastContainer />
      <div className='flex-row-start h-screen'>
      {user?.token !== null && <Sidebar />}
        <div className='w-full h-full min-h-screen overflow-auto basis-[100%]'>
        <Header />
        <Routes>
          {/* User Authentication Routes */}
          <Route path='auth' element={<Auth />}>
            <Route path='login' element={<Login />} />
          </Route>
          {/* Admin Routes */}
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/admins" element={<ProtectedRoute><Admins /></ProtectedRoute>} />
          <Route path="/admins/add-admin" element={<ProtectedRoute><AddAdmin /></ProtectedRoute>} />
          {/* Course Routes */}
          <Route path='courses' element={<ProtectedRoute><Course /></ProtectedRoute>}>
            <Route path='' element={<ProtectedRoute><CourseHome /></ProtectedRoute>}/>
            <Route path='add/:slug' element={<ProtectedRoute><CourseModules /></ProtectedRoute>}>
              <Route path='module/:moduleId/:moduleSlug' element={<ProtectedRoute><AddCourseChapter/></ProtectedRoute>}/>
            </Route>
          </Route>

        </Routes>
        </div>
      </div>
    </Router>
  )
}

export default App