import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import { linksAtom, userAtom } from '../../data/store'
import AdminLinkCard from './AdminLinkCard'
import { useNavigate } from 'react-router-dom/dist'

function Home() {
  const [user, setUser] = useAtom(userAtom)
  const [links] = useAtom(linksAtom)
  const navigate = useNavigate()


  useEffect(() => {
  if (!user.user || user.user === null || user.user === undefined|| user.token === null || user.token === undefined) {
    navigate('/auth/login')
  }
}, [user, navigate])

  let permissions = []
  if(user !== null && user !== undefined && user?.user?.permissions !== null && user?.user?.permissions !== undefined)
    permissions = user ? Object.keys(user?.user?.permissions[0]).filter((permission) => user?.user?.permissions[0][permission] === true) : []


  if(user.user)
    return (
      <section>
        <h2 className='heading'>Home</h2>
        <div className='grid grid-cols-3 gap-16 m-16'>
        {permissions.map((permission) => {
          return <AdminLinkCard key={permission} {...links.filter(link => link?.name === permission)[0]}/>
        })
      }
      </div>
      </section>
    )
}

export default Home